import { useEffect, useState } from 'react';

const useWindowLocation = () => {
  const isClient = typeof window !== 'undefined';
  const [location, setLocation] = useState(isClient ? window.location.href : '');

  useEffect(() => {
    if (!isClient) return;

    setLocation(window.location.href);

    const handleLocationChange = () => {
      setLocation(window.location.href);
    };

    window.addEventListener('popstate', handleLocationChange);

    return () => {
      window.removeEventListener('popstate', handleLocationChange);
    };
  }, [isClient]);

  return location;
};

export default useWindowLocation;
