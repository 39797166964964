import React, { useState } from 'react';
import calenderIcon from '../assets/images/calender-icon.webp';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import timeClock from '../assets/images/time-clock.webp';
import share from '../assets/images/share.webp';
import face from '../assets/images/face.webp';
import lin from '../assets/images/linked-in.webp';
import twiter from '../assets/images/twiter.webp';
import { format } from 'date-fns';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import useWindowLocation from '../customhooks/useWindowLocation';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function socialMedia({ data }: any) {
  const [tooltipText, setTooltipText] = useState('Click to copy link');
  const location = useWindowLocation();

  const copyLink = () => {
    navigator.clipboard.writeText(location);
    setTooltipText('Article URL Copied!');
    setTimeout(() => {
      setTooltipText('Click to copy link');
    }, 3000);
  };

  const tooltip = (
    <Tooltip id='tooltipText' className='tooltip-custom'>
      {tooltipText}
    </Tooltip>
  );

  function dateFormatBlog(date: any) {
    const date1 = new Date(date);
    const formattedDate = format(date1, 'MMMM do, yyyy');
    return formattedDate;
  }
  return (
    <div className=''>
      <div className='d-flex flex-column   flex-lg-row mb-4 align-items-lg-center mb-lg-5'>
        <div className='d-flex flex-row  align-items-center  detail-border-right pe-lg-3 pe-xl-4 pe-xxl-5 pb-4 pb-lg-0'>
          <LazyLoadImage
            effect='blur'
            className='me-3 blog-profile-image rounded-circle'
            src={data?.author?.profileImage.file.url}
            alt='profile-image'
          />
          <div className='d-flex flex-column'>
            <h4 className='fw-600 fnt-16 mb-1 pb-0 text-white'>{data?.author?.name}</h4>
            <p className='fnt-14 fw-400 mb-0 pb-0 text-white opacity-75'>
              {data?.author?.designation}
            </p>
          </div>
        </div>
        <div className='d-flex  flex-lg-row pb-4 pb-lg-0'>
          <div className='d-flex flex-row align-items-center pe-3 ps-lg-2 ps-xl-4 pe-lg-4 pe-xl-5  detail-border-right'>
            <LazyLoadImage
              effect='blur'
              src={calenderIcon}
              className='pe-2  opacity-50 calender-icon'
              alt='calender-icon'
            />
            <p className='text-white mb-0 pb-0 fnt-14'>{dateFormatBlog(data?.createdAt)}</p>
          </div>
          <div className='d-flex flex-row align-items-center ps-lg-2 ps-xl-4 pe-lg-4 pe-xl-5 '>
            <LazyLoadImage
              effect='blur'
              src={timeClock}
              className='opacity-50 pe-2 '
              alt='calender-icon'
            />
            <p className='text-white mb-0 pb-0 fnt-14'> {data?.timeToRead} mins read</p>
          </div>
        </div>
        <div className='ps-lg-2 ps-xl-4 ps-xxl-5'>
          <OverlayTrigger placement='bottom' overlay={tooltip}>
            <button className='btn' onClick={copyLink}>
              <LazyLoadImage
                effect='blur'
                className='me-4 me-lg-3 me-xl-4 me-xxl-5'
                src={share}
                alt='share'
              />
            </button>
          </OverlayTrigger>
          <FacebookShareButton url={location}>
            <LazyLoadImage
              effect='blur'
              src={face}
              className='me-4 me-lg-3 me-xl-4 me-xxl-5 '
              alt='facebook'
            />
          </FacebookShareButton>

          <LinkedinShareButton url={location}>
            <LazyLoadImage
              effect='blur'
              src={lin}
              className='me-4 me-lg-3 me-xl-4 me-xxl-5'
              alt='linked-in'
            />
          </LinkedinShareButton>

          <TwitterShareButton url={location}>
            <LazyLoadImage effect='blur' src={twiter} alt='twitter' />
          </TwitterShareButton>
        </div>
      </div>
    </div>
  );
}
